import styled from "styled-components";

export const SignatureFormContainer = styled.div`
  background-color: white;
  display: flex;
  width: 400px;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  padding-left: 0.5rem;
  padding-bottom: 0.3rem;
`;

export const Input = styled.input`
  width: 100%;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Button = styled.button``;

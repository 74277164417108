import React, { useState } from "react";
import axios from "axios";
import SignatureFormComponent from "../components/SignatureForm";
import Signature from "../components/Signature";
import {
  SignatureGeneratorContainer,
  SignatureContainer,
  Button,
} from "../styles/pages/signature-generator";
import { API_URI } from "../config";

const SignatureGenerator = () => {
  const [inputs, setInputs] = useState({});
  const [submitSucces, setSubmitSucces] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    if (event.target.files) {
      const file = event.target.files[0];
      setInputs((values) => ({ ...values, file }));
    }
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const body = { fileName: inputs.name, file: fileReader.result };
        const result = await axios.post(
          `${API_URI}/api/home-care/upload-file`,
          body
        );
        setInputs((inputs) => ({
          ...inputs,
          fileUrl: result?.data?.result?.url,
        }));
        setSubmitSucces(true);
        setSubmitLoading(false);
      } catch (err) {
        setSubmitError(true);
        setSubmitLoading(false);
      }
    };
    fileReader.readAsDataURL(inputs.file);
  };

  const handleCopyToClipboard = () => {
    var r = document.createRange();
    r.selectNode(document.getElementById("signature"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    window.alert("Copied signature");
  };

  if (submitLoading) {
    return (
      <SignatureGeneratorContainer>
        <div>Loading...</div>
      </SignatureGeneratorContainer>
    );
  }

  if (submitError) {
    return (
      <SignatureGeneratorContainer>
        <div>We got an error... please try again later</div>
      </SignatureGeneratorContainer>
    );
  }

  return (
    <SignatureGeneratorContainer>
      {!submitSucces && (
        <SignatureFormComponent
          handleOnSubmit={handleOnSubmit}
          handleChange={handleChange}
          inputs={inputs}
        />
      )}
      {submitSucces && (
        <SignatureContainer>
          <Signature inputs={inputs} />
          <Button onClick={handleCopyToClipboard}>Copy to clipboard</Button>
        </SignatureContainer>
      )}
    </SignatureGeneratorContainer>
  );
};

export default SignatureGenerator;
